<i18n src="./translations"></i18n>
<template>
  <div :class="classes" :data-preload="[$options.name]">
    <portal to="mobile-categories">
      <client-only v-if="isMobileNavOpen">
        <div class="mobile-nav-overlay" @click="closeMobileMenu"></div>

        <transition-group
          :enter-class="isParent ? 'drawerEnter' : 'drawerEnterRight'"
          enter-to-class="drawerEnterTo"
          enter-active-class="drawerEnterActive"
          leave-class="drawerEnterTo"
          :leave-to-class="isParent ? 'drawerEnter' : 'drawerEnterRight'"
          leave-active-class="drawerEnterActive"
        >
          <MobileCategories
            v-for="(menuItem, index) in visiblePanels"
            :key="menuItem.name || index"
            class="categoriesPosition"
            :parent-item="visiblePanels[index - 1]"
            :parent-index="index"
            :active-item="visiblePanels[index]"
            :items="menuItem.menuItems"
            :header-pretitle="
              menuItem.name === 'mainCategories' ? '' : $t('navigation.title')
            "
            :header-title="
              menuItem.topCategories
                ? $t('navigation.top_categories')
                : menuItem.title
            "
            :is-top-categories="menuItem.topCategory"
            :on-click-handler="selectMenuItem"
            :fetching="menuItem.fetching"
            :menu-items="menuItem.menuItems"
            :name="menuItem.name"
            :is-new-top-navigation-enabled="isNewTopNavigationEnabled"
          />
        </transition-group>
      </client-only>
    </portal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ClientOnly from 'vue-client-only';
import MobileCategories from 'Components/02-molecules/mobile-categories/MobileCategories.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import scrollMixin from 'Libs/mixins/scrollMixin';

export default {
  name: 'Navigation',
  components: {
    ClientOnly,
    MobileCategories,
  },
  mixins: [globalMixin, scrollMixin],
  computed: {
    ...mapState('navigation', {
      isMobileNavOpen: (state) => state.isMobileOpen,
      categories: (state) => state.data.menuItems,
      activeMenuItem: (state) => state.activeMenuItem,
    }),
    ...mapState('core', {
      isNewTopNavigationEnabled: ({ salesChannel }) =>
        salesChannel.config.isNewTopNavigationEnabled || false,
    }),
    isParent() {
      return (
        !this.activeMenuItem ||
        !(this.activeMenuItem && this.activeMenuItem.name)
      );
    },
    visiblePanels() {
      if (!this.activeMenuItem) return [];

      let visiblePanelList = [];
      let menuItems = this.categories;

      visiblePanelList.push({
        menuItems: menuItems,
        name: 'mainCategories',
        title: this.$t('navigation.start'),
        childrenCount: menuItems.length,
      });
      if (this.activeMenuItem.topCategories) {
        return [
          ...visiblePanelList,
          {
            ...this.activeMenuItem,
            title: this.$t('navigation.top_categories'),
          },
        ];
      }

      (this.activeMenuItem.path || []).forEach((pathItem) => {
        const item = menuItems.find(
          (categroyItem) => categroyItem.name === pathItem.name
        );
        if (item) {
          visiblePanelList.push(item);
          menuItems = item.menuItems || [];
        }
      });
      return visiblePanelList;
    },
  },
  methods: {
    ...mapActions('navigation', ['selectMenuItem', 'closeMobileNavigation']),
    closeMobileMenu() {
      this.enableBodyScrolling();
      this.closeMobileNavigation();
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.navigation {
  display: block;
}

.mobile-nav-overlay {
  position: fixed;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.categoriesPosition {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-color: var(--color-white);
}

.drawerEnter {
  transform: translateX(-100%);
}

.drawerEnterTo {
  transform: translateX(0);
}

.drawerEnterActive {
  display: block;
  transition: transform var(--time-S) ease-in;
}

.drawerEnterRight {
  transform: translateX(100%);
}
</style>
